
import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { AssignNewCustomerDeliverySlot, createcsadmin, modifyorder,ProcessOrderRefund } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar, Autocomplete, Paper } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const ASSING_DELIVERY_SLOT = gql`
  ${AssignNewCustomerDeliverySlot}
`;
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${modifyorder}
`;

const EDIT_ORDER_REFUND = gql`
  ${ProcessOrderRefund}
`;

const CustomPaperComponent = props => (
    <Paper {...props} style={{ background: 'white', color: 'black' }} />
  )
function ChangesDeliverySlot(props) {
  const { vendor ,onClose,handlereload, refetch ,payMethod ,sellingPrice } = props;
  console.log(props.vendor ,"data")
   // Get today's date in 'YYYY-MM-DD' format
   const today = new Date().toISOString().split('T')[0];
  const formRef = useRef();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const mutation = ASSING_DELIVERY_SLOT;
  let [error, errorSetter] = useState('');
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const userType = JSON.parse(localStorage.getItem("user-enatega"))
  const [openSnackk, setopenSnackk] = useState(false);
  const orderId = localStorage.getItem('orderId')
  const [formData, setFormData] = useState({
    id: userType._id,
    name: props.vendor ? props.vendor.name : '',
    quantity: props.vendor ? props.vendor.quantity : '',
    instruction: '',
    skuId: props.vendor ? props.vendor.skuId : '',
    productId: props.vendor ? props.vendor.productId : '',

  });

  const handleSlotChange = (event, value) => {
    setSelectedSlot(value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'quantity' && parseInt(value) > props.vendor.quantity) {
      errorSetter("Please enter a quantity less than or equal to the current quantity.");
      setopenSnackk(true);
      setTimeout(() => {
        errorSetter('');
        setopenSnackk(false);
      }, 3000);
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onCompleted =async (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Quantity Updated Successfullly') : t('StoreAddedSuccessfully');
    errorSetter('');
    successSetter(message);
    refetch()
    setopenSnackk(true)
    // setTimeout(hideAlert, 3000);
      // Conditionally call the ProcessOrderRefund mutation
      handlereload()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    handlereload()
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter("Something Went Wrong");
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const [mutate] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      id: userType._id,
      name: props.vendor ? props.vendor.name : '',
      quantity: props.vendor ? props.vendor.quantity : '',
      instruction: '',
    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
 
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {t('Change Delivery Slot ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>

        <Grid container spacing={2}>  <>
          <Grid item xs={12} sm={6}>
            <TextField name="name" value={vendor.deliveryDate} label=" Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} disabled />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
            />
          </Grid> */}
          
          <Grid item xs={12} sm={6}>
                <TextField
                  label="Select Date"
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                    min: today, // Restrict the date picker to today's date or future dates
                 
                  }}
                  sx={{
                    width: { xs: '100%', sm: '50%', md: '100%', lg: '100%' },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
         
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={vendor && vendor.customerDeliverySlot  && vendor.customerDeliverySlot.timeSlot || []}
              getOptionLabel={(option) => `${option.start} - ${option.end}`}
              onChange={handleSlotChange}
              renderInput={(params) => <TextField {...params} label="Select Time Slot" variant="outlined"
              
              inputProps={{ ...params.inputProps, style: inputTextStyle }}/>
            
            }
              
              PaperComponent={CustomPaperComponent}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="instruction" label="Reason" value={formData.email} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} required />
          </Grid>

        </>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          className={globalClasses.dashbordBtn}
          // disabled={mutateLoading || !formData.instruction}
          onClick={async (e) => {
            e.preventDefault();
            if (!formData.instruction) {

              errorSetter("Please Fill Comment")
              setopenSnackk(true)
              setTimeout(() => {
                errorSetter('')
                setopenSnackk(false)
              }, 3000);
              return;
            } else {
              errorSetter('')
            }

            // Your existing mutation logic here
        
              mutate({
                variables: {
                    slotId: selectedSlot._id,
                    date: selectedDate, // Format date as YYYY-MM-DD
                    reason: formData.instruction,
                    orderId: vendor._id,
                  },

           

              });
            // Close the modal after 3 seconds by calling the parent's onClose callback
            setTimeout(() => {
              if (typeof props.onClose === 'function') {
                props.onClose(); // Close the modal
              }
            }, 4000);
          }}
        >
          {props.vendor ? t('Update') : t('Save')}
        </Button>

        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(ChangesDeliverySlot);
