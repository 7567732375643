import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {  GetStore,UpdateLandingPage } from '../../apollo';
import {
  Button, Box, Grid, TextField,Typography, Snackbar, Container, Paper, DialogActions, Alert,
  Switch
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useGlobalStyles from '../../utils/globalStyles';
import Header from '../Headers/Header';

const GET_STORE__LANDINGPAGE = gql`${GetStore}`;
const UPDATE_STORE_LANDINGPAGE = gql`${UpdateLandingPage}`;

const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

const LANDING_PAGE_DEVICE_TYPE = Object.freeze({
  MOBILE: 0,
  WEB: 1,

});

const LANDING_PAGE_DISPLAY = Object.freeze({
  OFFERS: 0,
  FAVORITES: 1,
  NEW: 2,
  CATEGORIES: 3,
  SUB_CATEGORIES: 4,
  GENERAL: 5,
  BANNERS: 6,
});

const LANDING_PAGE_DEVICE_TYPE_OPTIONS = [
  { value: LANDING_PAGE_DEVICE_TYPE.MOBILE, label: 'Mobile' },
  { value: LANDING_PAGE_DEVICE_TYPE.WEB, label: 'Web' },

];

const LANDING_PAGE_DISPLAY_OPTIONS = [
  { value: LANDING_PAGE_DISPLAY.OFFERS, label: 'Offers' },
  { value: LANDING_PAGE_DISPLAY.FAVORITES, label: 'Favorites' },
  { value: LANDING_PAGE_DISPLAY.NEW, label: 'New' },
  { value: LANDING_PAGE_DISPLAY.CATEGORIES, label: 'Categories' },
  { value: LANDING_PAGE_DISPLAY.SUB_CATEGORIES, label: 'Sub Categories' },
  { value: LANDING_PAGE_DISPLAY.GENERAL, label: 'General' },
  { value: LANDING_PAGE_DISPLAY.BANNERS, label: 'Banners' },
];

function LandingPage(props) {
  const globalClasses = useGlobalStyles();
  const formRef = useRef();
  const client = useApolloClient();
  const { t } = props;

  const userDetailsStr = localStorage.getItem('user-enatega');
  const userDetails = JSON.parse(userDetailsStr);

  const {  data } = useQuery(
    GET_STORE__LANDINGPAGE, {
        fetchPolicy: "no-cache",
      variables: { storeId: userDetails ? userDetails.storeId : null },
      onError: error => {
        const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
        if (isForbiddenError) {
          localStorage.removeItem('user-enatega');
          localStorage.removeItem('restaurant_id');
          client.clearStore();
          props.history.push('/auth/login');
        }
      },
    }
  );

  const [deviceType, setDeviceType] = useState(null);
  const [landingPageDisplays, setLandingPageDisplays] = useState([]);
  const [success, successSetter] = useState('');
  const [error, errorSetter] = useState('');
  const [openSnackk, setopenSnackk] = useState(false);

  const inputTextStyle = { color: 'black' };

  useEffect(() => {

    let selectedDeviceDisplays
    if (data && data.getStore && data.getStore.landingPage) {
       selectedDeviceDisplays = data.getStore.landingPage.landingPageDisplays.find(
        display => display.deviceType === deviceType
        
      );
      if (selectedDeviceDisplays) {
        setLandingPageDisplays(selectedDeviceDisplays.displays);
      } else {
        setLandingPageDisplays([]);
      }
    }
  }, [data, deviceType]);

  const handleDeviceTypeChange = (event, value) => {
    setDeviceType(value ? value.value : null);
  };
  const handleDisplayChange = (index, field, value) => {
    setLandingPageDisplays(prevDisplays => prevDisplays.map((display, i) =>
      i === index ? { ...display, [field]: value } : display
    ));
  };

  const onCompleted = (data) => {
  
    const message =  t('Landing Page Updated Successfully') 
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
  const    error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)

  };
  const [mutate, { loading: mutateLoading }] = useMutation(UPDATE_STORE_LANDINGPAGE, {

    onCompleted, onError,
  
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
      displayInput: {
        deviceType: deviceType,
        displayParams: landingPageDisplays.map(display => ({
          displayType: parseInt(display.displayType),
          enabled: display.enabled,
          rows: parseInt(display.rows),
          columns: parseInt(display.columns),
          position: parseInt(display.displayPosition),
        })),
      },
    };

    mutate({
      variables: formattedData,
    });

    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 1000);
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };

  return (
    <>
      <Header />
      <Container className={globalClasses.flexBox}>
        <Grid sx={{ backgroundColor: 'white', py: 4, margin: "1rem", padding: "1rem" }}>
          <form ref={formRef} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={LANDING_PAGE_DEVICE_TYPE_OPTIONS}
                  getOptionLabel={(option) => option.label}
                  value={LANDING_PAGE_DEVICE_TYPE_OPTIONS.find(option => option.value === deviceType) || null}
                  onChange={handleDeviceTypeChange}
                  renderInput={(params) => <TextField {...params} label="Device Type" variant="outlined"
                    inputProps={{ ...params.inputProps, style: inputTextStyle }} />}
                  PaperComponent={CustomPaperComponent}
                />
              </Grid>
              {landingPageDisplays.map((display, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                    disabled
                      options={LANDING_PAGE_DISPLAY_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      value={LANDING_PAGE_DISPLAY_OPTIONS.find(option => option.value === display.displayType) || null}
                      onChange={(e, value) => handleDisplayChange(index, 'displayType', value ? value.value : '')}
                      renderInput={(params) => <TextField {...params} label="Display Type" variant="outlined"
                        inputProps={{ ...params.inputProps, style: inputTextStyle }} />}
                      PaperComponent={CustomPaperComponent}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                   
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color:display.enabled ? 'gray' : 'red' }}>
                      Enabled
                      </Typography>
                      <Switch  label="Enabled"   checked={display.enabled}   onChange={(e) => handleDisplayChange(index, 'enabled', e.target.checked)} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                    type='number'
                      label="Rows"
                      variant="outlined"
                      fullWidth
                      value={display.rows}
                      inputProps={{ style: inputTextStyle }}
                      onChange={(e) => handleDisplayChange(index, 'rows', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                     type='number'
                      label="Columns"
                      variant="outlined"
                      fullWidth
                      value={display.columns}
                      inputProps={{ style: inputTextStyle }}
                      onChange={(e) => handleDisplayChange(index, 'columns', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                     type='number'
                      label="Position"
                      variant="outlined"
                      fullWidth
                      value={display.displayPosition}
                      inputProps={{ style: inputTextStyle }}
                      onChange={(e) => handleDisplayChange(index, 'displayPosition', e.target.value)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <DialogActions>
              <Box mt={2} sx={{ m: 1, position: 'relative' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={mutateLoading}
                >
                  {t('Update')}
                </Button>
              </Box>
            </DialogActions>
          </form>
          <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
        </Grid>
      </Container>
    </>
  );
}

export default withTranslation()(LandingPage);
