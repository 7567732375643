import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  DialogActions,
  Alert,
  Snackbar,
  Container,
} from '@mui/material';
import useGlobalStyles from '../../utils/globalStyles';
import { GetStore, UpdatePremiumCustomerCronTimeSlot, updateworkerdata } from '../../apollo';
import Header from '../Headers/Header';

const UPDATE_CUSTOMER_TIME_SLOT = gql`${UpdatePremiumCustomerCronTimeSlot}`;
const EDIT__LOCAL_STORE_ADMIN = gql`${updateworkerdata}`;
const GET_STORE__LANDINGPAGE = gql`${GetStore}`;
const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function CustomerTimeSlot(props) {
  const { onClose } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__LOCAL_STORE_ADMIN : UPDATE_CUSTOMER_TIME_SLOT;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [openSnackk, setopenSnackk] = useState(false);

  const userDetailsStr = localStorage.getItem('user-enatega');
  const userDetails = JSON.parse(userDetailsStr);
  const { t } = props;
  const [formData, setFormData] = useState({
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
  });

  const client = useApolloClient();
  const {  data } = useQuery(
    GET_STORE__LANDINGPAGE, {
        fetchPolicy: "no-cache",
        variables: { storeId: userDetails ? userDetails.storeId : null },
        onError: error => {
            const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
            if (isForbiddenError) {
                localStorage.removeItem('user-enatega');
                localStorage.removeItem('restaurant_id');
                client.clearStore();
                props.history.push('/auth/login');
            }
        },
    }
  );

  useEffect(() => {
    if (data && data.getStore && data.getStore.removeUpgradedCustomerSlot) {
        const { start, end } = data.getStore.removeUpgradedCustomerSlot;
        const [startHour, startMinute] = start.split(':');
        const [endHour, endMinute] = end.split(':');
        setFormData({
            startHour: startHour || '',
            startMinute: startMinute || '',
            endHour: endHour || '',
            endMinute: endMinute || '',
        });
    }
  }, [data]);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value || '' });
  };

  const onCompleted = (data) => {
    const message = props.vendor ? t('Time Config Updated Successfully') : t('Time Config Added Successfully');
    errorSetter('');
    successSetter(message);
    // props.refetch();
    setopenSnackk(true);
    setTimeout(hideAlert, 3000);
  };


  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message;
      errorSetter(error);
      setopenSnackk(true);
    } else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true);
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false);
  };

  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  return (
    <>
      <Header />
      <Container className={globalClasses.flexBox}>
        <Grid sx={{ backgroundColor: 'white', py: 4, margin: "1rem", padding: "1rem" }}>
          <form ref={formRef}>
            <Grid container spacing={2}>
              {!props.editModal ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: "black" }}>
                      Start Time 
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="start-hour"
                      options={hours}
                      getOptionLabel={(option) => option}
                      value={formData.startHour}
                      onChange={(event, value) => handleInputChange('startHour', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hour"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      PaperComponent={CustomPaperComponent}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="start-minute"
                      options={minutes}
                      getOptionLabel={(option) => option}
                      value={formData.startMinute}
                      onChange={(event, value) => handleInputChange('startMinute', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Minute"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      PaperComponent={CustomPaperComponent}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: "black" }}>
                      End Time
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="end-hour"
                      options={hours}
                      getOptionLabel={(option) => option}
                      value={formData.endHour}
                      onChange={(event, value) => handleInputChange('endHour', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hour"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      PaperComponent={CustomPaperComponent}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="end-minute"
                      options={minutes}
                      getOptionLabel={(option) => option}
                      value={formData.endMinute}
                      onChange={(event, value) => handleInputChange('endMinute', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Minute"
                          variant="outlined"
                          inputProps={{ ...params.inputProps, style: inputTextStyle }}
                        />
                      )}
                      PaperComponent={CustomPaperComponent}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="start"
                      value={formData.start}
                      label="Start Time"
                      variant="outlined"
                      fullWidth
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="end"
                      value={formData.end}
                      disabled
                      label="End Time"
                      variant="outlined"
                      fullWidth
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
          <DialogActions>
            <Button
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}
              onClick={async (e) => {
                e.preventDefault();
                const startTime = formData.startHour && formData.startMinute ? `${formData.startHour}:${formData.startMinute}` : '';
                const endTime = formData.endHour && formData.endMinute ? `${formData.endHour}:${formData.endMinute}` : '';
                mutate({
                  variables: {
                    start: startTime,
                    end: endTime,
                  },
                });
                setTimeout(() => {
                  if (typeof props.onClose === 'function') {
                    props.onClose();
                  }
                }, 4000);
              }}
            >
              { t('Update')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Grid>
      </Container>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
}

export default withTranslation()(CustomerTimeSlot);
