import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Box,
  Alert,
  Typography,
  Input,
  Button,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel, Switch,
} from '@mui/material';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { useMutation, gql } from '@apollo/client';
import { AdminLogin, workerlogin } from '../apollo';
import useStyles from '../components/Configuration/styles';
import useGlobalStyles from '../utils/globalStyles';
import LoginPageIcon from '../assets/svg/roostyicon.png';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const LOGIN = gql`
  ${AdminLogin}
`;
const LOGIN_WORKER = gql`
  ${workerlogin}
`;

const Login = (props) => {
  const history = useHistory(); // Initialize useHistory hook
  const [showPassword, setShowPassword] = useState(false);
  const [roleLoggedIn, setRoleLoggedIn] = useState(null);
  const admin = "ADMIN"
  const [stateData, setStateData] = useState({
    email: '',
    password: '',
    emailError: null,
    passwordError: null,
    error: null,
    type: null, /// 0 for vendor
    redirectToReferrer: !!localStorage.getItem('user-enatega'),
  });
  const [adminLogin, setAdminLogin] = useState({ type: 'ADMIN' });

  const [loginData, setloginData] = useState({
    "email": stateData.email,
    "password": stateData.password
  })
  const formRef = useRef();
  const { t } = props;

  const [isLogged, setIsLogged] = useState(false);
  const onBlur = (event, field) => {
    setStateData({
      ...stateData,
    
    });
  };

  const { redirectToReferrer, type } = stateData;

  useEffect(() => {
    if (isLogged && adminLogin.type === 'ADMIN') {
      if (redirectToReferrer && type === 0) {
        history.replace('/restaurant/list'); // Use history.replace instead of props.history.replace
      }
      if (redirectToReferrer && admin === 'ADMIN') {
        if (roleLoggedIn === "ADMIN") {
          history.replace('/super_admin/dashboard'); // Use history.replace instead of props.history.replace
        } else if (roleLoggedIn === 'CENTRALADMIN') {
          history.replace('/cs_admin/dashboard'); // Use history.replace instead of props.history.replace
        }
        else if (roleLoggedIn === 'LOCALADMIN') {
          history.replace('/local_admin/dashboard'); // Use history.replace instead of props.history.replace
        }
      }
    }
    else
      if (isLogged && adminLogin.type === 'WORKER') {

        if (redirectToReferrer && type === 0) {
          history.replace('/restaurant/list');
        }
        if (redirectToReferrer && admin === 'ADMIN') {
          if (roleLoggedIn === "PICKPACKADMIN") {
            history.replace('/package_admin/dashboard');
          } else if (roleLoggedIn === 'BILLINGADMIN') {
            history.replace('/billing_admin/dashboard');
          }
          else if (roleLoggedIn === 'BILLINGADMIN') {
            history.replace('/billing_admin/dashboard');
          }
        }
      }


  }, [isLogged, type, roleLoggedIn, redirectToReferrer, admin, history, adminLogin]);

  const onCompleted = (data) => {
    if (adminLogin.type === "ADMIN") {

      let userType = data.adminLogin.userType;
      if (userType === 0) {
        // userType = 'ADMIN'; // Map userType value of 0 to "ADMIN"
        setRoleLoggedIn('ADMIN');
      } else if (userType === 1) {
        // userType = 'LOCALADMIN'; // Map userType value of 0 to "ADMIN"
        setRoleLoggedIn('CENTRALADMIN');
      }
      else if (userType === 2) {
        // userType = 'LOCALADMIN'; // Map userType value of 0 to "ADMIN"
        setRoleLoggedIn('LOCALADMIN');
      }
      const modifiedData = {
        ...data.adminLogin,
        userType: userType,
      };
      localStorage.setItem('user-enatega', JSON.stringify(modifiedData));
      localStorage.setItem('userRole', "Admin");
      if (userType === 'VENDOR') {
        setStateData({
          ...stateData,
          redirectToReferrer: true,
          type: 0,
          emailError: null,
          passwordError: null,
        });
      } else {
        setStateData({
          ...stateData,
          redirectToReferrer: true,
          type: 1,
          emailError: null,
          passwordError: null,
        });
      }


    }

    else if (adminLogin.type === "WORKER") {


      let userType = data.workerLogin.workerType;
      if (userType === 0) {
        setRoleLoggedIn('PICKPACKADMIN');
      } else if (userType === 1) {
        setRoleLoggedIn('BILLINGADMIN');
      }
      else if (userType === 2) {
        setRoleLoggedIn('RIDER');
      }
      const modifiedData = {
        ...data.workerLogin,
        userType: userType,
      };
      localStorage.setItem('user-enatega', JSON.stringify(modifiedData));
      localStorage.setItem('userRole', "Worker");
      if (userType === 'VENDOR') {
        setStateData({
          ...stateData,
          redirectToReferrer: true,
          type: 0,
          emailError: null,
          passwordError: null,
        });
      } else {
        setStateData({
          ...stateData,
          redirectToReferrer: true,
          type: 1,
          emailError: null,
          passwordError: null,
        });
      }
    }
    setIsLogged(true);
    setTimeout(hideAlert, 5000);
  };

  const hideAlert = () => {
    setStateData({
      ...stateData,
      emailError: null,
      passwordError: null,
    });
  };
  const onError = (error) => {
    if (error.graphQLErrors.length) {
      setStateData({
        ...stateData,
        error: error.graphQLErrors[0].message,
      });
    }
    if (error.networkError) {
      setStateData({
        ...stateData,
        error: error.message,
      });
    }
    setIsLogged(false);
    setTimeout(hideAlert, 5000);
  };
  const [mutate] = useMutation(LOGIN, { onError, onCompleted });
  const [mutateWorker] = useMutation(LOGIN_WORKER, { onError, onCompleted });

  const loginFunc = async () => {

    if (adminLogin.type === 'ADMIN') {
      mutate({ variables: { ...stateData } });
    } else if (adminLogin.type === 'WORKER') {
      mutateWorker({ variables: { ...stateData } });
    }
  };
  const handleSwitchChange = (event) => {
    const newType = event.target.checked ? 'WORKER' : 'ADMIN';
    setAdminLogin({ ...adminLogin, type: newType });
  };
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <>
      <Grid
        container
        sx={{
          // backgroundImage: `url(${LoginBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}>
        <Grid
          item
          lg={6}
          xs={12} // Take full width on extra small screens (mobile)
          sm={6} // Take half width on small screens (tablet)
          pt={5}
          pb={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center', // Center text within the grid
          }}
        >
          <img
            src={"https://cdn.nativenest.in/pro/logo/Native-Nest-Green-Logo.jpg"}
            alt="login img"
            className={classes.loginImage}
          />
        </Grid>

        <Grid
          item
          lg={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center', // Center text within the grid
            // minHeight: '100vh', // Ensure the grid takes up the full viewport height
            padding: '0 20px', // Add padding to the sides
          }}
        >
          <Box container sx={{ width: '100%', maxWidth: 600 }} className={classes.container}>


            <Box
            // className={classes.flexRow}
            >
              <Box item className={classes.loggin_heading}>
                <Typography variant="h6" className={classes.text}>
                  {t('LogintoEnatega')}

                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 200 }}>
                  {t('enterYourDetailsBelow')}

                </Typography>
              </Box>

            </Box>
            <Box
              pl={3}
              pr={4}
              pt={2}
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginLeft: "26px" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch onChange={handleSwitchChange} />}
                  label={adminLogin.type === 'ADMIN' ? t('Admin Login') : t('Worker Login')}
                />
              </FormGroup>

            </Box>
            <Box className={classes.form}>
              <form ref={formRef}>
                <Box>
                  <Typography className={classes.labelText}>
                    {t('EmailLogin')}
                  </Typography>
                  <Input
                    style={{ marginTop: -1 }}
                    id="input-email"
                    name="input-email"
                    value={stateData.email}
                    onChange={event => {

                      setStateData({ ...stateData, email: event.target.value })
                      setloginData({ ...loginData, email: event.target.value })
                    }}
                    onBlur={event => {
                      onBlur(event, 'email')
                    }}
                    placeholder={t('Email')}
                    type="email"
                    disableUnderline
                    className={[
                      globalClasses.input,
                      stateData.emailError === false
                        ? globalClasses.inputError
                        : stateData.emailError === true
                          ? globalClasses.inputSuccess
                          : ''
                    ]}
                  />
                </Box>
                <Box>
                  <Typography className={classes.labelText}>
                    {t('Password')}
                  </Typography>
                  <Input
                    style={{ marginTop: -1 }}
                    id="input-password"
                    name="input-password"
                    placeholder={t('Password')}
                    value={stateData.password}
                    type={showPassword ? 'text' : 'password'}
                    onChange={event => {
                      setStateData({
                        ...stateData,
                        password: event.target.value
                      })
                      setloginData({ ...loginData, password: event.target.value })
                    }}
                    onBlur={event => {
                      onBlur(event, 'password')
                    }}
                    disableUnderline
                    className={[
                      globalClasses.input,
                      stateData.passwordError === false
                        ? globalClasses.inputError
                        : stateData.passwordError === true
                          ? globalClasses.inputSuccess
                          : ''
                    ]}
                    endAdornment={
                      <InputAdornment position="end">
                        <Checkbox
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                          color="primary"
                          icon={<VisibilityOffIcon />}
                          checkedIcon={<VisibilityIcon />}
                        />
                      </InputAdornment>
                    }
                  />
                </Box>

                <Box>
                  <Button
                    // className={globalClasses.button100}
                    style={{ marginTop: "0.5rem" }}
                    className={globalClasses.dashbordBtn}
                    onClick={loginFunc}>
                    {t('Login')}
                  </Button>
                </Box>
              </form>
              <Box mt={2}>
                {stateData.error && (
                  <Alert
                    className={globalClasses.alertError}
                    variant="filled"
                    severity="error">
                    {/* {stateData.error} */}
                    "Invalid username or password. Please try again."

                  </Alert>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default withTranslation()(Login)
