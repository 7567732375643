
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import CustomLoader from '../components/Loader/CustomLoader'
import { AddCreditToCustomerWalletForOrder, CancelOrder, getInvoiceByOrderId, getOrderDetailsforworker } from '../apollo'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/orderTable'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Modal,
  Typography,
  Box,Snackbar,Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material'
import TableHeader from '../components/TableHeader'
import useStyles from '../components/Section/styles';
import EditOrderByWorker from '../components/EditOrder/EditOrderByWorker'
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment'; // Import moment library
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Icon } from '@iconify/react';
import Divider from '@mui/material/Divider';
import { MdOutlineDirectionsBike, MdOutlineCheckCircle, MdOutlineCancel, MdErrorOutline, MdLocalShipping, MdDone, MdAssignment } from 'react-icons/md';
import {
  MdPersonAdd,
  MdPerson,
  MdPersonOutline,
  MdPersonPin,
  MdHourglassEmpty,
  MdAccessTime,
} from 'react-icons/md';
import AssignWorker from '../components/AssingWorker/AssignWorker'
import ConfigurableValues from '../config/constants'
import ViewWalletBYOrder from '../components/ViewWallet/ViewWalletBYOrder'
import Refund from '../components/Refund/Refund'
import html2pdf from "html2pdf.js";
import ejs from 'ejs';

const GET_ORDERDETAILS = gql`
  ${getOrderDetailsforworker}
`
const GET_INVOICE_BY_ORDER_ID = gql`
${getInvoiceByOrderId}`
// const GET_WORKERDETAILS = gql`
//   ${GetWorkerDetailsById}
// `
const CANCEL_ORDER =gql`
${CancelOrder}`
const ITEM_AMOUNT_REFUND = gql`
${AddCreditToCustomerWalletForOrder}`
const SingleorderDetails = props => {
  const golbalClasses = useGlobalStyles()
  const {
    SERVER_URL,
  } = ConfigurableValues()
  const { t } = props;
  const [openSnackk, setopenSnackk] = useState(false);
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const userDetails = JSON.parse(localStorage.getItem('user-enatega'))
  const orderDetails = JSON.parse(localStorage.getItem("orderDetails"))
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [refundModal, setRefundModal] = useState(false)
  const userType = JSON.parse(localStorage.getItem('user-enatega')).userType; // Assuming you have a function to get the user type
  let routePath = '';
  const [getInvoice, { data:invoiceData, loading, error:invoiceError }] = useLazyQuery(GET_INVOICE_BY_ORDER_ID);
  const [open, setOpen] = useState(false);
  const [cancelOrder] = useMutation(CANCEL_ORDER);
  const renderEjsTemplate = (data) => {
    // EJS template as a string
    const template = `
     <!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>

<h2>INVOICE</h2>
<p style="font-weight: bold">NATIVE ROOTS RETAIL PRIVATE LIMITED<br/>
No.53, Kaveri Layout, Hebbal, Kempapura<br/>
 Bangalore North, Bangalore - 560024, Karnataka</p>

<table width=950>
  <tr>
    <td colspan="7" style="font-weight: bold">Details of Receiver</td>
    <td colspan="2" style="font-weight: bold">Date</td>
      
   <td colspan="5">
  <%= new Date(invoice.invoiceDate).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }) %>
  &nbsp; <!-- Adds a gap between date and time -->
  <%= new Date(invoice.invoiceDate).toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' }) %>
</td>


  </tr>
  <tr>
    <td colspan="2" style="font-weight: bold">Name</td>
    <td colspan="5"><%= invoice.customerName %></td>
    <td colspan="2" style="font-weight: bold">EInvoice #</td>
    <td colspan="5"><%= invoice._id %></td>
  </tr>
  <tr>
    <td colspan="2" style="font-weight: bold">Address</td>
    <td colspan="5"><%= invoice.deliveryAddress.details +","+invoice.deliveryAddress.deliveryAddress %></td>
   
     <td colspan="7"><%= "Phone: "+invoice.customerPhone%></td>
  </tr>
    <tr>
    <td colspan="2" style="font-weight: bold">State</td>
    <td colspan="5">Karnataka</td>
    <td colspan="7"></td>
   
  </tr>
   
    <tr>
    <td colspan="2" style="font-weight: bold">State code</td>
    <td colspan="5"></td>
   
     <td colspan="7"></td>
  </tr>
   
    <tr>
        <td colspan="2" style="font-weight: bold">GSTIN</td>
        <td colspan="5"><%= invoice.customerGST %></td>
        <td colspan="2" style="font-weight: bold">Order Id: </td>
        <td colspan="5"><%= invoice.orderId %></td>
    
  </tr>
     <td colspan="14"><br/></td>
 
  </tr>
  <tr height=16>
  <td rowspan=2 height=32 class=xl80 width=31 style='height:25.5pt;border-top:
  none;width:23pt;font-weight: bold'>SL NO</td>
  <td rowspan=2 class=xl80 width=116 style='border-top:none;width:87pt;font-weight: bold'>Description
  of Goods</td>
  <td rowspan=2 class=xl80 width=56 style='border-top:none;width:42pt;font-weight: bold'>HSN Code</td>
  <td rowspan=2 class=xl80 width=35 style='border-top:none;width:26pt;font-weight: bold'>Ord Qty</td>

  <td rowspan=2 class=xl80 width=52 style='border-top:none;width:39pt;font-weight: bold'>Units</td>
  <td rowspan=2 class=xl80 width=59 style='border-top:none;width:44pt;font-weight: bold'>Rate</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Total</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Discount</td>
  <td rowspan=2 class=xl80 width=71 style='border-top:none;width:53pt;font-weight: bold'>Taxable
  Value</td>
  <td colspan=2 class=xl80 width=107 style='border-left:none;width:80pt;font-weight: bold'>CGST</td>
  <td colspan=2 class=xl80 width=107 style='border-left:none;width:80pt;font-weight: bold'>SGST</td>
 </tr>
  <tr height=16>
  <td height=16 class=xl67 width=44 style='height:12.75pt;border-top:none;
  border-left:none;width:33pt'>Rate</td>
  <td class=xl67 width=63 style='border-top:none;border-left:none;width:47pt;font-weight: bold'>Amount</td>
  <td class=xl67 width=44 style='border-top:none;border-left:none;width:33pt;font-weight: bold'>Rate</td>
  <td class=xl67 width=63 style='border-top:none;border-left:none;width:47pt;font-weight: bold'>Amount</td>
 </tr>
 <!-- This row will be repeated for each items-->
    <% if (invoice.items.length > 0) { %> <%
      let i = 0
    invoice.items.forEach(item => { ++i %>
  <tr height=33>
  <td height=33 class=xl65 align=right style='height:25.5pt;border-top:none'><%= i %></td>
  <td class=xl68 width=116 style='border-top:none;border-left:none;width:87pt'><%= item.name %></td>
  <td class=xl66 width=56 style='border-top:none;border-left:none;width:42pt'><%= item.hsn %></td>
  <td class=xl69 style='border-top:none;border-left:none'><%= item.quantity %></td>

  <td class=xl69><%= item.unit %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.sellingPrice %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.cost %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.discount %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.taxableValue %></td>
  <td class=xl71 align=right style='border-top:none;border-left:none'><%= item.CGST.rate %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.CGST.amount %></td>
  <td class=xl71 align=right style='border-top:none;border-left:none'><%= item.SGST.rate %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= item.SGST.amount %></td>
 </tr>
 <% });%> <% }%>
 <!-- Items row end here-->
  <tr height=16 >
  <td colspan=6 height=16 class=xl79 style='height:12.75pt; text-align: right' >Total</td>
  <td class=xl70 align=right style='border-top:none;border-left:none;'><%= invoice.billDetails.itemsTotal %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= invoice.billDetails.itemsSavings %></td>
  <td class=xl70 align=right style='border-top:none;border-left:none'><%= invoice.billDetails.itemsTaxableValue %></td>
  <td colspan=2 class=xl70 align=right style='border-left:none'><%= invoice.billDetails.itemsCGST %></td>
  <td colspan=2 class=xl70 align=right style='border-left:none'><%= invoice.billDetails.itemsSGST %></td>
 </tr>
  <tr height=16 >
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Total Invoice
  Value(in figures)</td>
  <td colspan=6 class=xl77 align=right style='border-left:none;font-weight: bold'><%= invoice.billDetails.grandTotal %></td>
 </tr>
 <tr height=16 >
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Total Invoice
  Value(in words)</td>
  <td colspan=6 class=xl75 width=356 style='border-left:none;width:266pt;font-weight: bold;text-align: right'><%= invoice.billDetails.words %></td>
  
 </tr>
<tr height=16>
  <td colspan=8 height=16 class=xl72 style='height:12.75pt;font-weight: bold;text-align: right'>Net Total</td>
  <td colspan=6 class=xl78 style='border-left:none;font-weight: bold;text-align: right'><%= invoice.billDetails.grandTotal %></td>
 </tr>
</table>
</body>
</html>


    `;
  
    // Use ejs.render to inject data into the template
    return ejs.render(template, data);
  };
  const [viewWalletModal, setViewWalletModal] = useState(false)

  switch (userType) {
    case 0:
      routePath = '/super_admin/allorders';
      break;
    case 1:
      routePath = '/cs_admin/allorders';
      break;
    case 2:
      routePath = '/local_admin/allorders';
      break;
    default:
      routePath = '/super_admin/allorders'; // Default to super admin if userType is not defined
      break;
  }
  const history = useHistory();
  const openaAddVendorModal = () => {
    setAddVendorModal(true);
  };
  const closeAddVendorModal = () => {
    setAddVendorModal(false);
  };

  const openRefundModal = () => {
    setRefundModal(true);
  };
  const closeRefundModal = () => {
    setRefundModal(false);
  };
  const formatDate = date => {
    const formattedDate = moment(date).format('Do MMMM YYYY, hh:mm a');
    return `${formattedDate}`
  };
  const handleBackClick = () => {
    history.push(routePath); // Navigate back based on user type
  };

  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const [addCreditToCustomerWalletForOrder] = useMutation(ITEM_AMOUNT_REFUND);
  const handleAddCredit = ( amount) => {
    addCreditToCustomerWalletForOrder({
      variables: {
        orderId: orderDetails._id,
        amount: amount, // Pass the selling price here
        description: "" // Add a description if needed
      }
    })
    .then(response => {
      console.log("Credit added successfully:", response.data);
      if(response.data){
        const message = props.vendor ? t('Refund added successfully') : t('Product Added Successfully');
        errorSetter('');
        successSetter(message);
        setTimeout(hideAlert, 3000);
        setopenSnackk(true)
      }
      // Handle success (e.g., show a success message)
    })
    .catch(error => {
      console.error("Error adding credit:", error);
      errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    });
  };
  
  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const { loading: loadingQuery, data, refetch } = useQuery(
    GET_ORDERDETAILS, {
    variables: {
      orderId: orderDetails._id
    }
  }
  )

  // const [getWorkerDetails, { loading: loadingWorker, data: workerData }] = useLazyQuery(GET_WORKERDETAILS);

  const openaVeiwWalletModal = () => {
    setViewWalletModal(true)
  }
  const closeViewWalletModal = () => {
    setViewWalletModal(false)
  }
  useEffect(() => {
    if (data && data.getOrderDetailsById) {
      const { riderAssigned, ppWorkerAssigned, billingWorkerAssigned } = data.getOrderDetailsById.orderState;
      const workerId = ppWorkerAssigned || billingWorkerAssigned || riderAssigned;
      if (workerId) {
        // getWorkerDetails({ variables: { workerId: workerId } });
      }
    }
  }, [data,
    //  getWorkerDetails
  ]);



  const filtered = data && data.getOrderDetailsById.items


  const billDetails = data ? data.getOrderDetailsById.billDetails : null
  const customerType = data ? data.getOrderDetailsById.customer.customerType : null;
  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }
  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const getSellingPrice = (prices, customerType) => {
    const price = prices.find(price => price.customerType === customerType);
    return price ? price.sellingPrice : 'N/A';
  };
  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1
    },
    {
      name: "Name",
      selector: "name",
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Image",
      cell: (row) => <img src={row.image} alt={row.name} style={{ width: "50px", height: "50px" }} />
    },
    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },
    {
      name: "Net Price", // Add Net Price column
      cell: (row) => {
        const sellingPrice = getSellingPrice(row.prices, customerType); // Get selling price
        return row.quantity * sellingPrice; // Calculate net price
      }
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button onClick={openRefundModal}>
          Refund
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];
  
  const columnsPDF = [


    {
      name: "Name",
      selector: "name",

      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Description",
      selector: "description",

      style: { cursor: 'pointer', minWidth: "300px" },
    },

    {
      name: "Quantity",
      selector: "quantity"
    },
    {
      name: "MRP",
      selector: "mrp"
    },
    {
      name: "Selling Price",
      cell: (row) => getSellingPrice(row.prices, customerType)
    },

  ];

  const getWorkerStatus = (status) => {
    const statusMap = {
      0: { text: 'Not Started', icon: <MdHourglassEmpty style={{ color: '#66a1ff' }} /> },
      1: { text: 'Pp Assigned', icon: <MdPersonAdd style={{ color: '#66a1ff' }} /> },
      2: { text: 'Pp In Progress', icon: <MdPerson style={{ color: '#66a1ff' }} /> },
      3: { text: 'Pp Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      4: { text: 'Billing Assigned', icon: <MdPersonPin style={{ color: '#66a1ff' }} /> },
      5: { text: 'Billing In Progress', icon: <MdAccessTime style={{ color: '#66a1ff' }} /> },
      6: { text: 'Billing Completed', icon: <MdDone style={{ color: '#66a1ff' }} /> },
      7: { text: 'Rider Assign Manual', icon: <MdPersonOutline style={{ color: '#66a1ff' }} /> },
      8: { text: 'Rider Assigned', icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      9: { text: 'Rider Out For Delivery', icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      10: { text: 'Rider Delivered', icon: <MdDone style={{ color: '#66a1ff' }} /> }
    };

    const workerStatus = statusMap[status] || { text: 'N/A', icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {workerStatus.icon}
        <span style={{ marginLeft: '8px' }}>{workerStatus.text}</span>
      </div>
    );
  };
  const getOrderStatusWord = (statusNumber) => {
    const statusMap = {
      0: { text: "INITIATED", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      1: { text: "CONFIRMED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      2: { text: "PACKING", icon: <MdOutlineCheckCircle style={{ color: '#66a1ff' }} /> },
      3: { text: "BILLING", icon: <MdAssignment style={{ color: '#66a1ff' }} /> },
      4: { text: "READY FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      5: { text: "RIDER ASSIGNED", icon: <MdOutlineDirectionsBike style={{ color: '#66a1ff' }} /> },
      6: { text: "OUT FOR DELIVERY", icon: <MdLocalShipping style={{ color: '#66a1ff' }} /> },
      7: { text: "DELIVERED", icon: <MdDone style={{ color: '#66a1ff' }} /> },
      8: { text: "CANCELLED", icon: <MdOutlineCancel style={{ color: '#66a1ff' }} /> },
      9: { text: "FAILED OUT OF STOCK", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      10: { text: "FAILED INVALID DELIVERY ADDRESS", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      11: { text: "FAILED", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
      12: { text: "NO ITEM CART", icon: <MdErrorOutline style={{ color: '#66a1ff' }} /> },
    };

    const status = statusMap[statusNumber] || { text: "N/A", icon: null };
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {status.icon}
        <span style={{ marginLeft: '8px' }}>{status.text}</span>
      </div>
    );
  };

  const getPaymentMethod = (statusNumber) => {
    const statusMap = {
      0: "COD",
      1: "ONLINE",

    };

    return statusMap[statusNumber] || "N/A";
  };
  const getPhonePayRefundStatus = (statusNumber) => {
    const refundStatusMap = {
      0: "Not Applicable",
      1: "Refund Pending",
      2: "Refund Initiated",
      3: "Refund Success",
      4: "Refund Failed",
      5: "Refund Applicable"
    };
  
    return refundStatusMap[statusNumber] || "N/A";
  };
  const getPaymentStatus = (statusNumber) => {
    const statusMap = {
      0: "PENDING",
      1: "COMPLETED",

    };

    return statusMap[statusNumber] || "N/A";
  };


  const handlePrint = () => {
    const doc = new jsPDF();
    
    // Add 'Net Price' column to the table header
    const tableColumn = ['Sl No', 'Name', 'Quantity', 'MRP', 'Selling Price', 'Net Price']; 
    
    // Adjust tableRows to remove 'description' and add 'Net Price'
    const tableRows = filtered.map((row, index) => {
      const sellingPrice = getSellingPrice(row.prices, customerType); // Get selling price
      const netPrice = row.quantity * sellingPrice; // Calculate net price
      return [
        index + 1,               // Sl No
        row.name,                // Name
        row.quantity,            // Quantity
        row.mrp,                 // MRP
        sellingPrice,            // Selling Price
        netPrice                 // Net Price (quantity * selling price)
      ];
    });
  
    doc.setFontSize(12);
    let currentY = 20;
  
    // Header
    doc.setFontSize(16);
    doc.text('ORDER DETAILS', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
    doc.setFontSize(12);
  
    // Order Details
    doc.text(`Order Number: ${orderDetails._id}`, 14, currentY);
    currentY += 6;
    doc.text(`Order Date: ${formatDate(orderDetails.createdAt)}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Name: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.name : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Customer Phone: ${(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.phone : 'N/A'}`, 14, currentY);
    currentY += 6;
  
    // Displaying the total count of items
    const itemCount = filtered.length;
    doc.text(`Item Count: ${itemCount}`, 14, currentY);
    currentY += 6;
  
     // Handle long delivery address
     const address = data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress ?
     `${data.getOrderDetailsById.deliveryAddress.details} ,${data.getOrderDetailsById.deliveryAddress.deliveryAddress}`
     :
     'N/A';
    const wrappedAddress = doc.splitTextToSize(address, 180); // Adjust the width as needed
    doc.text('Customer Address:', 14, currentY);
    currentY += 6;
    doc.text(wrappedAddress, 14, currentY);
    currentY += wrappedAddress.length * 6;
  
    const customerTypeText = customerType === 0 ? 'Premium' : customerType === 1 ? 'Regular' : 'N/A';
    doc.text(`Customer Type: ${customerTypeText}`, 14, currentY);
    currentY += 6;
  
    // Payment Details above the table
    doc.text(`Payment Method: ${(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : 'N/A'}`, 14, currentY);
    currentY += 6;
    doc.text(`Payment Status: ${(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : 'N/A'}`, 14, currentY);
    currentY += 10;
  
    // Table of Items with the new Net Price column
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: currentY,
      theme: 'grid',
    });
  
    currentY = doc.lastAutoTable.finalY + 10;
    const rightAlignX = doc.internal.pageSize.getWidth() - 30;  // Move the value column a bit to the left
    const labelX = rightAlignX - 70;  // Reduce the gap between the label and value
    const renderBillingDetail = (label, value, strikeThrough = false) => {
      // Customize the symbols based on the label
      let formattedValue;
      
      if (label.includes('Discount') || label.includes('Coupon') || label.includes('Wallet') || label.includes('Nest')|| label.includes('Savings')) {
        formattedValue = value >= 0 ? `-${value}` : `${value}`;  // Always negative for discounts, coupons, wallets, nest
      } else if(label.includes('Delivery') || label.includes('Handling') ){
        formattedValue = value >= 0 ? `+${value}` : `${value}`;  // Positive for delivery and handling charges
      }else {
        formattedValue = value >= 0 ? ` ${value}` : `${value}`;
      }
    
      doc.text(label, labelX, currentY);  // Display label
    
      if (strikeThrough) {
        doc.setTextColor(150);  // Lighter color for strike-through items
        doc.line(rightAlignX - 10, currentY - 2, rightAlignX + 10, currentY - 2); // Strikethrough line
      }
    
      doc.text(formattedValue, rightAlignX, currentY);  // Display formatted value with + or - symbol
      currentY += 6;  // Move to the next line
      doc.setTextColor(0);  // Reset text color to black
    };
    
    // Usage in handlePrint function
    if (billDetails) {
      // Displaying billing details with custom + and - symbols
      renderBillingDetail('MRP:', billDetails.itemsMrp || 0);
      renderBillingDetail('Items Discount:', billDetails.itemSavings || 0);  // Display with -
      renderBillingDetail('Items Total Price:', billDetails.itemsTotal || 0);  // Display with +
    
      // Conditional rendering based on applied status for Coupon, Wallet, Nest
      if (billDetails.couponInfo && billDetails.couponInfo.applied) {
        renderBillingDetail('Coupon:', billDetails.couponInfo.amount || 0);  // Display with -
      }
    
      if (billDetails.walletInfo && billDetails.walletInfo.applied) {
        renderBillingDetail('Wallet:', billDetails.walletInfo.amount || 0);  // Display with -
      }
    
      if (billDetails.nestInfo && billDetails.nestInfo.applied) {
        renderBillingDetail('Nest:', billDetails.nestInfo.amount || 0);  // Display with -
      }
    
      // Handle waived charges with strike-through
      if (billDetails.chargesWaived === 3) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0, true);  // Display with + and strike through
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0, true);  // Display with + and strike through
      } else if (billDetails.chargesWaived === 2) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0, true);  // Display with + and strike through
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0);  // Display with +
      } else if (billDetails.chargesWaived === 1) {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0);  // Display with +
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0, true);  // Display with + and strike through
      } else {
        renderBillingDetail('Delivery Charges:', billDetails.deliveryCharge || 0);  // Display with +
        renderBillingDetail('Handling Charges:', billDetails.handlingCharge || 0);  // Display with +
      }
    
      // Add the horizontal line and other billing details as before
      const pageWidth = doc.internal.pageSize.getWidth();
      const halfPageWidth = pageWidth / 2;
      const rightSideWidth = pageWidth - 14;
      currentY += 2;
      doc.line(halfPageWidth, currentY, rightSideWidth, currentY);  // Horizontal line
      currentY += 4;
    
      renderBillingDetail('Total Savings:', billDetails.totalSaving || 0);
      renderBillingDetail('Net Price:', billDetails.grandTotal || 0);
    }
    
    
    // Save the document
    doc.save('order-details.pdf');
  };


// Helper function to decode Base64 to JSON
function base64ToJson(base64) {
  // Decode Base64 string
  const jsonString = atob(base64);
  // Parse the JSON string
  return JSON.parse(jsonString);
}

const handleViewDetails = () => {
  const orderId = (data && data.getOrderDetailsById) ? data.getOrderDetailsById._id : null;

  if (!orderId) {
    console.error('Order ID not found');
    return;
  }

  getInvoice({
    variables: { orderId: orderId },
    onCompleted: (response) => {
      const base64Invoice = response.getInvoiceByOrderId; // Assuming this is the Base64 encoded JSON
      console.log("Base64 JSON:", base64Invoice);

      if (base64Invoice) {
        try {
          // Decode Base64 to JSON
          const base64Prefix = "data:application/json;base64,";
          const base64Data = base64Invoice.startsWith(base64Prefix)
            ? base64Invoice.substring(base64Prefix.length)
            : base64Invoice;

          // Convert Base64 to JSON object
          const jsonData = base64ToJson(base64Data);
          console.log(jsonData, "jsonData");

          // Render the EJS template
          const renderedHtml = renderEjsTemplate({ invoice: jsonData });

          // Create a new div to hold the rendered HTML content
          const pdfContent = document.createElement('div');
          pdfContent.innerHTML = renderedHtml;

          // Use html2pdf to convert the content to a PDF with custom settings
          const opt = {
            margin: [1, 0.9], // 0.5-inch top/bottom, 0.3-inch left/right to avoid right-side cutoff
            filename: `invoice_${jsonData._id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 }, // Higher scale for better quality
            jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' } // A4 page format
          };

          // Generate the PDF and open it in a new tab
          html2pdf().from(pdfContent).set(opt).toPdf().get('pdf').then((pdf) => {
            const pdfBlob = pdf.output('blob');
            
            // Create a blob URL for the PDF
            const pdfUrl = URL.createObjectURL(pdfBlob);
            
            // Open the PDF in a new tab
            window.open(pdfUrl);
          });

        } catch (error) {
          console.error('Failed to decode Base64 or render EJS', error);
        }
      } else {
        console.error('Invoice data not found');
      }
    },
    onError: (error) => {
      console.error('Error fetching invoice', error);
    },
  });
};
  const getOrderWorkerEmail = (orderState) => {
    switch (orderState.workerStatus) {
      case 1:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 2:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 3:
        return orderState.ppWorkerAssignedEmail ? orderState.ppWorkerAssignedEmail : "N/A"
      case 4:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 5:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 6:
        return orderState.billingWorkerAssignedEmail ? orderState.billingWorkerAssignedEmail : "N/A";
      case 7:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 8:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      case 9:
        return orderState.riderAssignedEmail ? orderState.riderAssignedEmail : "N/A";
      default:
        return 'No Worker Assigned';
    }
  };

  // Handle the cancel action
  const handleCancelOrder = async () => {
    console.log(data.getOrderDetailsById._id ,"data.getOrderDetailsById._id")
    try {
      const { data } = await cancelOrder({ variables: { orderId: orderDetails._id, } });
      console.log('Cancel Order Response:', data);
      const message = "Order Canceled!.";
      errorSetter('');
      successSetter(message);
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
      refetch()
      setOpen(false); // Close the dialog after successful mutation
    } catch (error) {
      const message = "Something Went Wronge!.";
      errorSetter(message);
      successSetter('');
      setTimeout(hideAlert, 3000);
      setopenSnackk(true)
      console.error('Error canceling the order:', error);
    }
  };

  // Open the confirmation dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the confirmation dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>


      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right' }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={handlePrint}
          >
            <Icon icon="gridicons:print" style={{ color: "white" }} />  {t('Print')}
          </Button>
          <Button className={golbalClasses.dashbordBtn} onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box
              item
              className={
                classes.heading

                // props.section ? classes.headingBlack : classes.heading
              }>
              <Typography
                variant="h6"
                className={
                  classes.textWhite
                }>
                {t('Order Details')}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.form} style={{ height: "663px" }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid container item xs={12} md={6}>
              <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order ID :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById._id : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Customer Name :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.name : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Contact Number :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.headingText} style={{ display: 'inline-flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="fluent:phone-12-regular" width="20" height="20" style={{ color: " #66a1ff" }} />  {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.customer.phone : "N/A"}
                    {/* {data.getOrderDetailsById.customer.phone} */}
                  </Typography>
                </Grid>
               
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Item Count :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.items.length : "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Order Amount :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.orderAmount : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Typography className={classes.typographyOrder}>
                    Order Date :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>

                    {(data && data.getOrderDetailsById) ? formatDate(data.getOrderDetailsById.orderDate) : "N/A"}

                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}  >
                  <Typography className={classes.typographyOrder}  >
                    Deliver Date :

                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    {(data && data.getOrderDetailsById) ? data.getOrderDetailsById.deliveryDate : "N/A"}

                  </Typography>
                </Grid>

              </Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                  }} />
              </Grid>
            
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder}>
                    Payment Information :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder}>
                    Payment Method : {(data && data.getOrderDetailsById) ? getPaymentMethod(data.getOrderDetailsById.paymentMethod) : "N/A"}
                    <br />
                    Payment Status : {(data && data.getOrderDetailsById) ? getPaymentStatus(data.getOrderDetailsById.paymentStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Refund Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getPhonePayRefundStatus(data.getOrderDetailsById.refundStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Wallet Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.amount : "N/A"} ({data && data.getOrderDetailsById.billDetails.walletInfo ? data.getOrderDetailsById.billDetails.walletInfo.message : ""})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Nest Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.amount : "N/A"}(   {data && data.getOrderDetailsById.billDetails.nestInfo ? data.getOrderDetailsById.billDetails.nestInfo.message : ""}
                    )
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Coupon Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.amount : "N/A"}(   {data && data.getOrderDetailsById.billDetails.couponInfo ? data.getOrderDetailsById.billDetails.couponInfo.message : ""}
                    )
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                  Cancelled Info :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.amount : "N/A"} (                    {data && data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo ? data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message  != null ?  data.getOrderDetailsById.billDetails.cancelledInfo.nestInfo.message : "N/A"  : "N/A"})
                  </Typography>
                </Grid>
              </Grid>
              {userType === 1 ? (
                  <Box>
                    <Button
                      className={golbalClasses.dashbordBtn}
                      onClick={openaVeiwWalletModal}>
                      <Icon
                      width="20px" height="20px"
                        icon="flowbite:wallet-solid"
                        style={{ color: 'white' }}
                      />{' '}
                      {t('View Wallet')}
                    </Button>
                  </Box>
                ) : null}
              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                    // width: '100%', // Ensure full width
                  }} />
              </Grid>
              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Order Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getOrderStatusWord(data.getOrderDetailsById.orderState.status) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row">
                <Grid item xs={3} md={3}>
                  <Typography className={classes.typographyOrder} >
                    Worker Status :
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.typographyOrder} >
                    {data && data.getOrderDetailsById ? getWorkerStatus(data.getOrderDetailsById.orderState.workerStatus) : "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} container direction="row" alignItems="center">
  <Grid item xs={3} md={3}>
    <Typography className={classes.typographyOrder}>
      Worker Email:
    </Typography>
  </Grid>
  <Grid item xs={9} md={9} container alignItems="center">
    <Typography className={classes.typographyOrder} style={{ marginRight: '8px' }}>
      {data && data.getOrderDetailsById ? getOrderWorkerEmail(data.getOrderDetailsById.orderState) : "N/A"}
    </Typography>
 { userType === 2?  <Box>
      <Button
        className={golbalClasses.dashbordBtn}
        onClick={openaAddVendorModal}
      >
        <Icon icon="ic:baseline-edit" style={{ color: "white" }} /> {t('Change')}
      </Button>
    </Box> : null}
  </Grid>
</Grid>

              <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
                <Divider variant="middle"
                  sx={{
                    borderBottomWidth: 4, // Increase thickness
                    // borderColor: 'blue' // Change color
                    // width: '100%', // Ensure full width
                  }} />
              </Grid>



              <Grid item xs={12} md={12} >
                <Typography className={classes.typographyOrder} >
                  <Icon icon="mdi:address-marker-outline" width="24" height="24" style={{ color: " #66a1ff" }} /> Delivery Address
                </Typography>
                <Typography className={classes.typographyOrder}>
                  {data && data.getOrderDetailsById && data.getOrderDetailsById.deliveryAddress ?
                    ` ${data.getOrderDetailsById.deliveryAddress.details} ,${data.getOrderDetailsById.deliveryAddress.deliveryAddress}`
                    :
                    "N/A"
                  }

                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Box>
        <DataTable
          subHeader={true}

          title={<TableHeader title={t('Orders')} />}
          columns={columns}
          data={filtered}
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
        />

<Grid container >
          <Grid item xs={6} md={6}>
          </Grid>
          <Grid item container spacing={1} xs={12} md={6} style={
            {
              background: "white", marginTop: "10px", borderRadius: 20, padding: "5px"
            }
          }>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "15px" }}>
                <Icon icon="marketeq:price-tag" width="24" height="24" style={{ marginRight: '8px' }} /> MRP
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                 <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.itemsMrp : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="teenyicons:discount-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />Items Discount
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                - <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black" }} />
                {billDetails ? billDetails.itemSavings : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:price-tag-2-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} />Items Total price 
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                 <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black" }} />
            
                {billDetails ? billDetails.itemsTotal : 0}
              </Typography>
            </Grid>
            { billDetails && billDetails.couponInfo && billDetails.couponInfo.applied ?  
        
        <>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Coupon 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.couponInfo?billDetails.couponInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.walletInfo && billDetails.walletInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Wallet 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.walletInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}
            { billDetails && billDetails.nestInfo && billDetails.nestInfo.applied ?  
        
        <>
        <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="streamline:discount-percent-coupon" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Nest 
              </Typography>
            </Grid>
            
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.walletInfo?billDetails.nestInfo.amount : 0 : 0}
              </Typography>
            </Grid>
            </>: null}


           { (billDetails &&  billDetails.chargesWaived == 1)?
           <>
          
           <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px"  }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through",}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
            
            </>:
            (billDetails &&  billDetails.chargesWaived == 2)?
            <>
            
            <Grid item xs={6} md={6}>
                          <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                            <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through", }}>
                            + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                            {billDetails ? billDetails.deliveryCharge : 0}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                            <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" }}>
                            + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                            {billDetails ? billDetails.handlingCharge : 0}
                          </Typography>
                        </Grid>
                       
                        </>
            : 
            (billDetails &&  billDetails.chargesWaived == 3)?

<>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,textDecoration: "line-through",}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px",textDecoration: "line-through", }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
</>
: 
<>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="ri:e-bike-line" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Delivery Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px" ,}}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.deliveryCharge : 0}
              </Typography>
            </Grid>
<Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: "14px" }}>
                <Icon icon="tdesign:undertake-transaction" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Handling Charges
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "14px", }}>
                + <Icon icon="mdi:rupee" width="20" height="20" style={{ color: " black", }} />
                {billDetails ? billDetails.handlingCharge : 0}
              </Typography>
            </Grid>
</>}



            <Grid item xs={12} md={12} style={{ marginTop: "16px", marginBottom: "12px" }} >
              <Divider variant="middle"
                sx={{
                  borderBottomWidth: 4,
                }} />

                
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Icon icon="teenyicons:discount-outline" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Total Savings 
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                -<Icon icon="mdi:rupee" width="20" height="20" style={{ color: "black" }} />
                {billDetails ? billDetails.totalSaving : 0}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography style={{ color: "black", fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Icon icon="fluent:money-hand-16-regular" width="24" height="24" style={{ color: "#66a1ff", marginRight: '8px' }} /> Net Price
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
              <Typography variant="body1" style={{ fontWeight: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon icon="mdi:rupee" width="20" height="20" style={{ color: "black" }} />
                {billDetails ? billDetails.grandTotal : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
     
          {
            (data && data.getOrderDetailsById.invoiceId) ?
              <Button onClick={() => handleViewDetails()} className={globalClasses.dashbordBtn}>
                View Invoice
              </Button> :  null
}{
              (data && data.getOrderDetailsById.orderState.status == 8)?
             null
              :   <Button onClick={() => handleClickOpen()} className={globalClasses.dashbordDeleteBtn}>
              Cancel Order 
              </Button>

          }
        </Box>


        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditOrderByWorker vendor={vendors} editModal={editModal} onClose={closeEditModal} refetch={refetch} />
        </Modal>

        {addVendorModal ? <Grid container>
          <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
            <Grid
              sx={{ display: { xs: 'none', lg: 'block' } }}
              item
              mt={5}
              ml={-2}
              order={{ xs: 1, lg: 2 }}
            >
              <Modal
                open={addVendorModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClose={closeAddVendorModal}
              >
                <AssignWorker data={data} onClose={closeAddVendorModal} reload={addVendorModal} refetch={refetch} />
                {/* <VendorComponent onClose={closeAddVendorModal} /> */}
              </Modal>
            </Grid>
          </Grid>
        </Grid>
          :
          null
        }

<Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>

      {viewWalletModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={viewWalletModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeViewWalletModal}>
                  <ViewWalletBYOrder
                    data={orderDetails}
                    onClose={closeViewWalletModal}
                    reload={viewWalletModal}
                    refetch={refetch}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

{refundModal ? (
          <Grid container>
            <Grid item xs={12} order={{ xs: 2, lg: 1 }}>
              <Grid
                sx={{ display: { xs: 'none', lg: 'block' } }}
                item
                mt={5}
                ml={-2}
                order={{ xs: 1, lg: 2 }}>
                <Modal
                  open={refundModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClose={closeRefundModal}>
                  <Refund
                    data={orderDetails._id}
                    onClose={closeRefundModal}
                    reload={refundModal}
                    refetch={refetch}
                    total ={billDetails ? billDetails.grandTotal : 0}
                  />
                </Modal>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>

 
        <DialogTitle>
          <Box className={ classes.heading}>
            <Typography className={ classes.textWhite}>
        { t('Cancel Order! ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
        <DialogContentText>
            This action will cancel the order. Please confirm if you wish to proceed.
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
          onClick={handleCancelOrder}
          >
            { 'Yes'}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              handleClose()
            }}
          >
            Close
          </Button>
        </DialogActions>
    
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
      </Container>

    </>
  )
}

export default withTranslation()(SingleorderDetails)
