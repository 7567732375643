import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { gql, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import { FilterProductsByCategories, getproductsforcs, searchproductforcs  ,GetRefreshedData ,refreshAllDataFromExternalSource ,getRefreshResponse} from '../apollo'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import SearchBar from '../components/TableHeader/SearchBar'
import { customStyles } from '../utils/tableForDataRefresh'
import useGlobalStyles from '../utils/globalStyles'
import { useHistory } from 'react-router-dom';
import {
  Container,
  Modal,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  Typography,
  Paper,
  Badge,
  Box, Button,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete'
import TableHeader from '../components/TableHeader'
import Alert from '../components/Alert'
import AddProduct from '../components/AddProduct/AddProduct'
import TuneIcon from '@mui/icons-material/Tune';
import AllPRoductFIlter from '../utils/fIlters/AllPRoductFIlter'
import { Icon } from '@iconify/react';
import { useTheme } from '@emotion/react'
import ejs from 'ejs';
import moment from 'moment-timezone';
const GET_DATA = gql`
  ${GetRefreshedData}
`

const GET_REFRESH_RESPONSE = gql`
  ${getRefreshResponse}
`
const CREATE_NEW_REQUEST = gql`
  ${refreshAllDataFromExternalSource}
`;
const GET_SEARCH_PRODUCTS = gql`
  ${searchproductforcs}
`
const GET_ALL_PRODUCT_filter = gql`
${FilterProductsByCategories}
`
const ejsTemplate = `
<!DOCTYPE html>
<html>
 <head>
   <title>Data Fetch Response</title>
 </head>
 <body>
   <h1>Data Fetch Response</h1>
   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Store Name:
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Date :
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
        <%= refreshResponse?.storeName %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= moment.utc().tz('Asia/Kolkata') %>
       </td>
     </tr>
   </table>
   <p><b>1 - Customer Type Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         1.1 - Duplicates Customer types [ Customer Type IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value-add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.customerTypeResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p>
     <b style="color: #f00">1.2 - Existing Customer Types [ Customer Type IDs that are already present in the DB and cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Existing sub cat values start here-->
     <% if (refreshResponse?.customerTypeResponse?.existing?.length > 0) { %>
     <% refreshResponse?.customerTypeResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Existing sub cat values end here-->
   </table>

   <p />

   <p>
     <b>1.3 - Inserted Customer Types</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Inserted Catalog values start here-->
     <% if (refreshResponse?.customerTypeResponse?.insertedIds?.length > 0) {
     %> <% refreshResponse?.customerTypeResponse?.insertedIds?.forEach(item =>
     {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted Catalog values end here-->
   </table>
   <p><b>1.4 </b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         insertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.customerTypeResponse?.insertedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.customerTypeResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.customerTypeResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>
   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />

   <p><b>2 - Catalog Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         2.1 - Duplicates Catalog Ids [ Catalog IDs that are present, more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.catalogResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p style="color:#f00">
     <b >2.2 - Existing Catalogs [ Catalog IDs that are already present in the DB. Cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Existing sub cat values start here-->
     <% if (refreshResponse?.catalogResponse?.existing?.length > 0) { %> <%
     refreshResponse?.catalogResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Existing sub cat values end here-->
   </table>

   <p />

   <p>
     <b>2.3 - Inserted Catalogs</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Inserted Catalog values start here-->
     <% if (refreshResponse?.catalogResponse?.insertedIds?.length > 0) { %> <%
     refreshResponse?.catalogResponse?.insertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted Catalog values end here-->
   </table>
   <p><b>2.4 </b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         insertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.catalogResponse?.insertedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.catalogResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.catalogResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>

   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />

   <p><b>3 - Category Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         3.1 - Duplicates Ids [  Category IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.categoryResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p style="color:#f00">
     <b >3.2 - Existing Categories [ Category IDs that are already present in DB. Cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Existing cat values start here-->
     <% if (refreshResponse?.categoryResponse?.existing?.length > 0) { %> <%
     refreshResponse?.categoryResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Existing cat values end here-->
   </table>
   <p>
     <b>3.3 - Inserted Categories</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Inserted cat values start here-->
     <% if (refreshResponse?.categoryResponse?.insertedIds?.length > 0) { %> <%
     refreshResponse?.categoryResponse?.insertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted cat values end here-->
   </table>
   <p><b>3.4</b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         insertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.categoryResponse?.insertedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.categoryResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.categoryResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>
   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />
   <p><b>4 - Sub Category Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         4.1 - Duplicates sub cat Ids [ Sub Category IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.subCategoryResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p style="color:#f00">
     <b >4.2 - Existing Sub Categories [ Sub Category IDs that are already present in DB. Cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="

           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Existing sub cat values start here-->
     <% if (refreshResponse?.subCategoryResponse?.existing?.length > 0) { %> <%
     refreshResponse?.subCategoryResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Existing sub cat values end here-->
   </table>

   <p />
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         4.3 - Missing categories [ Category IDs of Sub Category that are missing in the DB. There are no such categories available in DB ]
       </th>
     </tr>
     <!-- Missing Categories -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.subCategoryResponse?.missingIds %>
       </td>
     </tr>
     <!-- Missing Categories - row ends-->
   </table>

   <p>
     <b>4.4 - Inserted sub Categories</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Inserted sub cat values start here-->
     <% if (refreshResponse?.subCategoryResponse?.insertedIds?.length > 0) { %>
     <% refreshResponse?.subCategoryResponse?.insertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted sub cat values end here-->
   </table>
   <p><b>4.5</b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         InsertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         CategoriesUpdatedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.subCategoryResponse?.insertedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.subCategoryResponse?.categoriesUpdatedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.subCategoryResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.subCategoryResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>
   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />
   <p><b>5 - Product Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         5.1 - Duplicates Product Ids [ Product IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p style="color:#f00">
     <b >5.2 - Existing Product [ Product IDs that are already existing in DB. Cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
         
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Existing Product values start here-->
     <% if (refreshResponse?.productResponse?.existing?.length > 0) { %> <%
     refreshResponse?.productResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>

     <!-- Existing Product values end here-->
   </table>

   <p />

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         5.3 - missingCategories Ids [ Category IDs of the products that are not in DB. These Categories do not exist]
       </th>
     </tr>
     <!--  value --add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.missingCategories %>
       </td>
     </tr>
     <!--  value - row ends-->
   </table>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         5.4 - missing sub Categories Ids [ Sub Category IDs of the products that are not in DB. These Sub Categories do not exist]
       </th>
     </tr>
     <!--  value --add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.missingSubCategories %>
       </td>
     </tr>
     <!--  value - row ends-->
   </table>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         5.5 - missing Catalogs Ids [ Catalog IDs of the products that are not in DB. These Catalogs do not exist]
       </th>
     </tr>
     <!--  value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.missingCatalogs %>
       </td>
     </tr>
     <!--  value - row ends-->
   </table>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         5.6 - existingCatalogDefaults Ids [ Catalog Ids of products that already have catalogDefault set to true. Only one product for a catalog can have catalogDefault true]
       </th>
     </tr>
     <!--  value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.existingCatalogDefaults %>
       </td>
     </tr>
     <!--  value - row ends-->
   </table>
   <p>
     <b>5.7 - Inserted Products</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Description
       </th>
     </tr>
     <!-- Inserted  values start here-->
     <% if (refreshResponse?.productResponse?.insertedIds?.length > 0) { %> <%
     refreshResponse?.productResponse?.insertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.description %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted  values end here-->
   </table>
   <p><b>5.8 </b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         insertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.insertedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.productResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.productResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>

   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />
   <p><b>6 - Sku Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         6.1 - Duplicates Sku Ids [Sku IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.skuResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
   <p style="color:#f00">
     <b >6.2 - Existing SKU [Sku IDs that are already present in DB. Cannot be inserted]</b>
   </p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         skuId
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         productId
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         stockId
       </th>
     </tr>
     <!-- Existing Product values start here-->
     <% if (refreshResponse?.skuResponse?.existing?.length > 0) { %> <%
     refreshResponse?.skuResponse?.existing?.forEach(item => {%>
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.productId %>
       </td>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.stockId %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Existing Product values end here-->
   </table>

   <p />

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         6.3 - missing Product Ids [Product IDs for SKU's that are not present in DB. These products ID do not exist]
       </th>
     </tr>
     <!--  value -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.skuResponse?.missingProducts %>
       </td>
     </tr>
     <!--  value - row ends-->
   </table>

   <p>
     <b>6.4 - Inserted Sku</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Product Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Stock Id
       </th>
     </tr>
     <!-- Inserted  values start here-->
     <% if (refreshResponse?.skuResponse?.insertedIds?.length > 0) { %> <%
     refreshResponse?.skuResponse?.insertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.productId %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.stockId %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted  values end here-->
   </table>
   <p>
     <b>6.5 - Inserted stock</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Name
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Store
       </th>
     </tr>
     <!-- Inserted  values start here-->
     <% if (refreshResponse?.skuResponse?.stockInsertedIds?.length > 0) { %> <%
     refreshResponse?.skuResponse?.stockInsertedIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.name %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.storeId %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- Inserted  values end here-->
   </table>

   <p><b>6.6 </b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Stock insertedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.skuResponse?.stockInsertedCount %>
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.skuResponse?.message %>
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.skuResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </th>
     </tr>
   </table>

   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />
   <p><b>7 - Price Fetch Response</b></p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         7.1 - Duplicates Sku Ids [ Sku IDs that are present more than once in the Response]
       </th>
     </tr>
     <!-- duplicate value-add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.priceUpdateResponse?.duplicates %>
       </td>
     </tr>
     <!-- duplicate value - row ends-->
   </table>
    <p />
<table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         7.2 - Missing Sku IDS [ SKU IDs that are missing in the DB. There are no such categories available in DB ]
       </th>
     </tr>
     <!-- Missing Sku -add multple rows bellow  -->
     <tr>
       <td
         style="color:#f00;
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.priceUpdateResponse?.missingSKUIds %>
       </td>
     </tr>
     <!-- Missing Skus - row ends-->
   </table>

   <p>
     <b>7.3 - Updated Skus</b>
   </p>

   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Id
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Prices
       </th>
     </tr>
     <!-- Inserted sub cat values start here-->
     <% if (refreshResponse?.priceUpdateResponse?.updatedSkuIds?.length > 0) { %>
     <% refreshResponse?.priceUpdateResponse?.updatedSkuIds?.forEach(item => {%>
     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item._id %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= item.prices %>
       </td>
     </tr>
     <% });%> <% }%>
     <!-- updated sku values end here-->
   </table>
   <p><b>7.4</b></p>
   <table
     style="width: 100%; border: 1px solid black; border-collapse: collapse"
   >
     <tr>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         SKUUpdatedCount
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Message
       </th>
       <th
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         Last updated
       </th>
     </tr>

     <tr>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.priceUpdateResponse?.updatedCount %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%= refreshResponse?.priceUpdateResponse?.message %>
       </td>
       <td
         style="
           border-width: 1px;
           border-style: solid;
           border-color: #000000;
           border-collapse: collapse;
           padding: 5px;
           text-align: center;
         "
       >
         <%=
         moment.utc(refreshResponse?.priceUpdateResponse?.lastUpdated).tz('Asia/Kolkata')
         %>
       </td>
     </tr>
   </table>
   <hr
     style="height: 5px; border-width: 0; color: gray; background-color: blue"
   />       
 </body>
</html>

`;
const DataRequestTab = props => {

  const history = useHistory();
  const { t } = props;
  const theme = useTheme();
  const [editModal, setEditModal] = useState(false)
  const [vendors, setVendor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const userDetailsStr = localStorage.getItem('user-enatega');
  const userDetails = JSON.parse(userDetailsStr);
  const userStoreId = userDetails.storeId;
  const golbalClasses = useGlobalStyles()
  const client = useApolloClient();
  const [page, setPage] = useState(
    1
  );
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);

  const [perPage, setPerPage] = useState(10)
  const [badgeContent, setbadgeContent] = useState(false);
  const [OpenFilter, setOpenFilter] = useState(false)
  var [FilterPaload, setFilterPaload] = useState({})
  var [DefaultValue, setDefaultValue] = useState({})
  const mutation = CREATE_NEW_REQUEST;
  const [openSnackk, setopenSnackk] = useState(false);
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  var [itemId, setItemId] = useState(null); // Track which item you're fetching data for
  const [fetchData, setFetchData] = useState(false); 
  const closeEditModal = () => {
    setEditModal(false);
    // setAddVendorModal(false);
  };
  const [reload, setReload] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const CloseFilter = () => {
    setOpenFilter(false)
  }
  const handleResetFilters = () => {
    setFilterPaload({});
    setDefaultValue({});
    setPage(1);
    setReload(!reload)
    setbadgeContent(false)
  };
  const variables = {
    // storeId: userDetails.storeId,
    limit: perPage,
    skip: (page - 1) * perPage,
    // skip: page,
  };


  // const variablesSearch = {
  //   text: searchQuery, // Assuming `text` is the variable used in your search query
  //   limit: perPage,
  //   skip: (page - 1) * perPage,
  // };


  const { loading: loadingQuery, error: errorQuery, data, refetch } = useQuery(GET_DATA, {
    variables: {
      limit: perPage,
      skip: (currentPage - 1) * perPage,
    },
    onCompleted: (data) => {
      const refreshedData = data && data.getRefreshedData.dataRefresh || [];
      setTableData( refreshedData);
      setTotalRows(data && data.getRefreshedData .totalCount || refreshedData.length);
      setReload(!reload);
    },
    onError: (error) => {
      if (error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')) {
        localStorage.removeItem('user-enatega');
        client.clearStore();
        history.push('/auth/login');
      }
    },
  });
  const openEjsInNewTab = (base64String) => {
    try {
      // Step 1: Decode the base64 string
      const decodedString = atob(base64String);
      console.log('Decoded String:', decodedString);
  
      // Step 2: Parse JSON
      let jsonData;
      try {
        jsonData = JSON.parse(decodedString);
      } catch (parseError) {
        throw new Error('Failed to parse JSON from decoded string');
      }
  
      // Step 3: Wrap the data in refreshResponse
      const formattedData = {
        refreshResponse: jsonData
      };
  
      // Step 4: Validate the structure
      if (!formattedData) {
        throw new Error('Invalid data format: refreshResponse not found');
      }
  console.log(formattedData ,"formattedData")
      // Step 5: Render EJS template
      const htmlContent = ejs.render(ejsTemplate, {
        refreshResponse: formattedData.refreshResponse,
        moment: moment // Pass moment here
      });
      console.log('HTML Content:', htmlContent);
  
      // Step 6: Create and open Blob
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const fileUrl = URL.createObjectURL(blob);
      const newTab = window.open(fileUrl, '_blank');
      if (!newTab) {
        throw new Error('Failed to open new tab');
      }
  
      // Clean up
      newTab.onload = () => {
        URL.revokeObjectURL(fileUrl);
      };
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
//   const { data:refreshData, refetch:refresh } = useQuery(GET_REFRESH_RESPONSE, {
//     variables: { itemId },
//     skip: !fetchData, // Skip query execution unless fetchData is true
    
//     onCompleted: (refreshData) => {
// openEjsInNewTab(refreshData.getRefreshResponse)
//     },
//     onError: error => {
//       const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');

//       if (isForbiddenError) {
//         localStorage.removeItem('user-enatega');
//         localStorage.removeItem('restaurant_id');
//         client.clearStore();
//         props.history.push('/auth/login');
//       }
//     },
  
//   });

const handleButtonClick = async (id) => {
  try {
    console.log("i am calling ", id);
    setItemId(id); // Update the itemId state
    setFetchData(true); // Trigger the query when button is clicked

    // Call the API directly here
    const { data: refreshData } = await client.query({
      query: GET_REFRESH_RESPONSE,
      variables: { refreshId: id },
      fetchPolicy: 'no-cache', // Avoid caching issues
    });
console.log(refreshData.getRefreshResponse )
    // Handle the API response
    openEjsInNewTab(refreshData.getRefreshResponse);
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error appropriately
    // const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
    //  if (isForbiddenError) {
    //   localStorage.removeItem('user-enatega');
    //   localStorage.removeItem('restaurant_id');
    //   client.clearStore();
    //   props.history.push('/auth/login');

      // Handle other errors
      setopenSnackk(true);
      errorSetter(error.message || 'Something went wrong!');
      setTimeout(hideAlert, 3000);
  
  }
};

  const [refetchfilterData] = useLazyQuery(GET_ALL_PRODUCT_filter, {
    fetchPolicy: 'no-cache',

    onCompleted: (resultData) => {
      if (resultData && resultData.filterProductsByCategories) {
        CloseFilter()
        tableData = resultData.filterProductsByCategories.products || []
        setTableData(tableData);
        setTotalRows(resultData.filterProductsByCategories.totalCount || 0);
      }
    },
    onError: error => {
      const isForbiddenError = error && error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-enatega');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },

  });
  const onCompleted = (data) => {

    const message =t('Fetcted Data Successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
    refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter(error);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)

  };
  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
  
    onError,
    onCompleted,
  });
  const filterData = (filterInput) => {
    setDefaultValue(filterInput)
    FilterPaload = {

      "categoryId": filterInput.orderStatus,
      "subCategoryId": filterInput.workerStatus,
      "limit": perPage,
      "skip": (page - 1) * perPage,
    }
    //  setFilterPaload(filterInput)
    refetchfilterData({
      variables: FilterPaload,
    })
    setbadgeContent(true)
  }
  const onChangeSearch = e => {
    setSearchQuery(e.target.value)
    setTimeout(() => {
      // refetchSearch()
    }, 1000)

  }
  const onClickRefetch = () => {
    refetch()
    setbadgeContent(false)
    setReload(!reload)
    setFilterPaload({});
    setDefaultValue({});
  }

 

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };

  const toggleModal = vendor => {
    setEditModal(!editModal)
    setVendor(vendor)
  }

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  
  const columns = [
    {
      name: "ID",
      selector: '_id',
      sortable: true,
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Store ID",
      selector: 'storeId',
      sortable: true,
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Encoded Response",
      selector: 'encodedResponse',
      cell: row => row.encodedResponse || 'N/A', // Show 'N/A' if null
      sortable: true,
      // style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Created At",
      selector: 'createdAt',
      sortable: true,
      cell: row => new Date(row.createdAt).toLocaleString(), // Format as human-readable date/time
      // style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: t('Action'),
      cell: (row) => <>{actionButtons(row)}</>,
    
    }
  ];
  

  const actionButtons = row => {
console.log("calling in side actionn ", row)
    return (
      <>
        <Button
          size="20px"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.common.white,
            padding: 0,
            height: '15px',
            fontSize: '7px',
            '&:hover': {
              border: '2px solid #637BFE',
              color: theme.palette.warning.dark,
              backgroundColor: "white"
            }
          }}
          onClick={() => handleButtonClick(row._id)} 
        >
          {t('ViewDetails')}
        </Button>
      </>
    )
  }
const fetchNewData = ()=>{

  mutate({})
}
  return (
    <>
      <Header />
      {isOpen && (
        <Alert
          message={t('AvailableAfterPurchasing')}
          severity="warning"
        />
      )}

      <Container className={golbalClasses.flex}>
      <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Button

            className={golbalClasses.dashbordBtn}
            onClick={fetchNewData}
          >
            <Icon icon="ic:twotone-plus" style={{ color: "white" }} /> {t('GET NEW DATA')}
          </Button>
        </Box>
        {errorQuery ? <span> `Error! ${errorQuery.message}`</span> : null}
        {loadingQuery ? (
          <CustomLoader />
        ) : (
          <DataTable
            subHeader={true}
            // subHeaderComponent={
            //   <>
            //     <SearchBar
            //       value={searchQuery}
            //       onChange={onChangeSearch}
            //       onClick={() => onClickRefetch(refetch)}
            //     />
            //     <Box style={{ marginTop: -40 }}>
            //       {badgeContent === true ? (
            //         <Badge
            //           badgeContent={''}
            //           color="success"
            //           variant="dot"
            //           anchorOrigin={{
            //             vertical: 'top',
            //             horizontal: 'center',
            //           }}
            //         >
            //           <Button
            //             onClick={handleOpenFilter}
            //           >

            //             <TuneIcon />
            //           </Button>
            //         </Badge>
            //       ) : <Button
            //         onClick={handleOpenFilter}
            //       >

            //         <TuneIcon />
            //       </Button>}
            //     </Box>
            //   </>
            // }
            title={<TableHeader title={t('Data Load History ')} />}
            columns={columns}
            data={tableData || []}
            progressPending={loadingQuery}
            progressComponent={<CustomLoader />}
            paginationDefaultPage={currentPage}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            sortFunction={customSort}

            customStyles={customStyles}
          />
        )}
        <Modal
          open={editModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          BackdropProps={{
            onClick: (e) => {
              e.stopPropagation(); // Prevent the modal from closing
            }
          }}
          onClose={() => {
            toggleModal()
          }}>
          <AddProduct vendor={vendors} onClose={closeEditModal} />
        </Modal>
        {/* <Modal
          open={skuModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClose={() => {
            toggleModal()
          }}>
          <EditProductQuantityCS vendor={vendors} onClose={closeSkuModal} />
        </Modal> */}


        {OpenFilter &&
          <AllPRoductFIlter open={OpenFilter} close={CloseFilter} filterData={filterData} DefaultValue={DefaultValue} handleResetFilters={handleResetFilters} />
        }
      </Container>
    </>
  )
}

export default withTranslation()(DataRequestTab)
