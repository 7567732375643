
import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { getalllocalstore, AddNewCustomerType, updatelocaladmin, UpdateAdminPassword, AddSpecialOffer, UpdateSpecialOffer } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar, Switch } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const GET_LOCAL_STORE = gql`
  ${getalllocalstore}
`;
const CREATE_OFFER_TYPE = gql`
  ${AddSpecialOffer}
`;
const UPDATE_OFFER_TYPE  = gql`
  ${UpdateSpecialOffer}
`;




function AddCustomerType(props) {
  const { onClose, filtered } = props;
  const formRef = useRef();
  const mutation = props.vendor ? UPDATE_OFFER_TYPE  : CREATE_OFFER_TYPE;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    id : props.vendor ? props.vendor._id : '',
    name: props.vendor ? props.vendor.name : '',
    isActive: props.vendor ? props.vendor.isActive : false,
    displayStyle : props.vendor ? props.vendor.displayStyle  : '',
    image: props.vendor ? props.vendor.image : '',
    action: props.vendor ? props.vendor.action : ''
  });
  
  const [passwordModified, setPasswordModified] = useState(false);
  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter("Something Went Wrong");
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Offer  Updated Successfully') : t('Offer  Added Successfully');
    errorSetter('');
    props.refetch()
    successSetter(message);
    setTimeout(hideAlert, 3000);
    props.refetch()
    setopenSnackk(true)
  };

  const [openSnackk, setopenSnackk] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    if (name === "customerType") {
      const customerTypeExists = filtered.some((item) => item.customerType === parseInt(value));
      if (customerTypeExists) {
        setFormErrors({ ...formErrors, [name]: 'Offer  already exists' });
        return;
      } else {
        setFormErrors({ ...formErrors, [name]: '' });
      }
    }
    setFormData({ ...formData, [name]: value });
    if (name === 'password' && props.vendor) {
      setPasswordModified(true);
    }
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };


  const validateFormData = () => {
    let errors = {};

    if (!formData.name) errors.name = 'Name is required';
    // if (!formData.displayStyle ) errors.displayStyle   = 'Display Style is required';
    if (!formData.displayStyle  && !props.editModal) {
        errors.displayStyle  = 'Display Style is required';
      }
    if (!formData.action) errors.action = 'Action required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCAL_STORE }],
    onError,
    onCompleted,
  });
  const clearFields = () => {
    formRef.current.reset();

    setFormData({
        name: props.vendor ? props.vendor.name : '',
        isActive: props.vendor ? props.vendor.isActive : false,
        displayStyle : props.vendor ? props.vendor.displayStyle  : '',
        image: props.vendor ? props.vendor.image : '',
        action: props.vendor ? props.vendor.action : ''

    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form data
    if (!validateFormData()) {
      return; // Exit if validation fails
    }
  
    // Mutate depending on whether it's an edit or add operation
    if (props.editModal) {
      // Update existing offer
      mutate({
        variables: {
          updateSpecialOfferId: formData.id,
          offerInput: {
            name: formData.name,
            isActive: formData.isActive,
            displayStyle: parseInt(formData.displayStyle),
            image: formData.image,
            action: formData.action,
          },
        },
      });
    } else {
      // Create new offer
      mutate({
        variables: {
          offerInput: {
            name: formData.name,
            isActive: formData.isActive,
            displayStyle: parseInt(formData.displayStyle),
            image: formData.image,
            action: formData.action,
          },
        },
      });
    }
  
    // Close the modal after 4 seconds
    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose();
      }
    }, 4000);
  };
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <form ref={formRef} onSubmit={handleSubmit}>
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Update Special Offer') : t('Add Special Offer')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>

          <Grid container spacing={2}>
            {!props.editModal ?
              <>
           
  <Grid item xs={12} sm={6}>
    <TextField
      name="name"
      label="Name"
      variant="outlined"
      fullWidth
      value={formData.name}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.name}
      helperText={formErrors.name}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="displayStyle "
      label="Display Style"
      variant="outlined"
      fullWidth
      type='number'
      value={formData.displayStyle }
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.displayStyle }
      helperText={formErrors.displayStyle }
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="image"
      label="Image URL"
      variant="outlined"
      fullWidth
      value={formData.image}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.image}
      helperText={formErrors.image}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="action"
      label="Action"
      variant="outlined"
      fullWidth
      value={formData.action}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.action}
      helperText={formErrors.action}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography style={{color:"black"}}>  Is Active</Typography>
    <Switch
      checked={formData.isActive}
      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
      name="isActive"
      inputProps={{ 'aria-label': 'is active' }}
    />
  </Grid>



              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>
        
        <Grid item xs={12} sm={6}>
    <TextField
      name="name"
      label="Name"
      variant="outlined"
      fullWidth
      value={formData.name}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.name}
      helperText={formErrors.name}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="displayStyle "
      label="Display Style"
      variant="outlined"
      fullWidth
      type='number'
      value={formData.displayStyle }
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.displayStyle }
      helperText={formErrors.displayStyle }
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="image"
      label="Image URL"
      variant="outlined"
      fullWidth
      value={formData.image}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.image}
      helperText={formErrors.image}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      name="action"
      label="Action"
      variant="outlined"
      fullWidth
      value={formData.action}
      onChange={handleInputChange}
      inputProps={{ style: inputTextStyle }}
      error={!!formErrors.action}
      helperText={formErrors.action}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography style={{color:"black"}}>  Is Active</Typography>
    <Switch
      checked={formData.isActive}
      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
      name="isActive"
      inputProps={{ 'aria-label': 'is active' }}
    />
  </Grid>



                </>
              )
            }
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? t('Update') : t('Add')}
          </Button>
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddCustomerType);
